require('./vendors/bootstrap');
require('./vendors/jquery');

require('./vendors/jquery.cookie');

// Magnific Popup

// Nice Select
require('./vendors/nice-select/nice-select');

// Owl Carousel
require('./vendors/owl-carousel/owl.carousel');

// Slick Slider
require('./vendors/slick-slider/slick');
require('./vendors/slick-slider/slick-aminations');

// Jarallax
require('./vendors/jarallax/jarallax');

// One Page Nav
require('./vendors/one-page-nav/jquery.nav.js');

// Jquery Mask
require('./vendors/jquery-mask/jquery.mask.min.js');

// Background Slider
require('./vendors/background-slider/background-slider.js');

// Jquery Validate
require('./vendors/validate.js');